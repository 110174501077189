import React, { useEffect, useState } from "react"

// Provider
import { useTheme } from "../context/ThemeContext"

// Utilities
import lazyLoad from "../utilities/handleLazyLoad"

// Data
import OFFERS_DATA from "../../static/data/products/offers"

// Components
const Loader = lazyLoad(() => import("../components/Loader/Loader"))
const BuyBox = lazyLoad(() => import("../components/ProductBuyBox/BuyBox"))
const ScrollTopButton = lazyLoad(() => import("../components/Scrollable/ScrollTopButton"))
const ProgramProteinPromo = lazyLoad(() => import("../products/program-protein-promo"))
const OfferTimer = lazyLoad(() => import("../components/Offer/OfferTimer"))

const OffersTemplate = ({ pageContext }) => {
  const { product } = pageContext
  const { pageLoader } = useTheme()

  const shopifyProductId = product.shopifyId
  const currentProduct = OFFERS_DATA[shopifyProductId]

  /*============================
    CUSTOM GTM EVENT
  =============================*/
  useEffect(() => {
    let items = [
      {
        item_id: shopifyProductId,
        item_name: product.title,
        currency: "USD",
        item_brand:
          currentProduct.type === "supplements" || currentProduct.type === "bundles"
            ? "Whole Betty"
            : "The Betty Rocker",
        item_category: currentProduct.type,
        price: +product.variants[0].price,
        quantity: 1,
      },
    ]

    if (typeof window !== "undefined" && window.dataLayer) {
      console.log(items)
      window.dataLayer.push({
        event: "view_item",
        ecommerce: {
          item_list_name: currentProduct.type,
          items: items,
        },
      })
    }
  }, [])

  /*============================
   TABS
 =============================*/
  const [updateActiveTab, setUpdateActiveTab] = useState(currentProduct.tabs[0])

  const handleActiveTab = e => setUpdateActiveTab(e.substring(1))

  return (
    <>
      {currentProduct ? (
        <>
          {/*Offer Timer */}
          <OfferTimer product={currentProduct} />

          {/*product hero*/}
          <BuyBox shopifyProduct={product} product={currentProduct} hero={true} />

          {/*Offer Content*/}
          <ProgramProteinPromo
            shopifyProduct={product}
            product={currentProduct}
            tabs={currentProduct.tabs}
            updateActiveTab={updateActiveTab}
          />

          {/*scrollTop**/}
          <ScrollTopButton
            isSupplement={true}
            handleActiveTab={handleActiveTab}
            isButtonOnly={!currentProduct.tabs.length}
            tabs={currentProduct.tabs}
          />

          {/*BuyBox*/}
          <BuyBox shopifyProduct={product} product={currentProduct} hero={false} />
        </>
      ) : (
        <Loader isLoader={pageLoader} />
      )}
    </>
  )
}
export default OffersTemplate
